import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class TerminalApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].terminalApi+'/terminal/',
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    getSsbtData() {
        return this.Api.get('ssbtStateData')
    }
      
}

export {
    TerminalApi
}
