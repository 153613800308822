<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.ssbtStatus') }}</h5>
                <DataTable :lazy="true" :value="filtredItems" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" :totalRecords="totalPages" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" :showClear="true" v-model="shopsModel" :options="shops" optionLabel="name" :placeholder="$t('search.byShop')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="activeFilter" :options="activeOptions" :placeholder="$t('search.byStatus')" optionLabel="option" :filter="!this.isMobile()" />
                            </div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="shopCode" :header="$t('system.code')"></Column>
                    <Column field="shopName" :header="$t('system.shop')"></Column>
                    <Column field="timeSinceOnline" :header="$t('system.timeSinceOnline')"></Column>
                    <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge status-' + data.isOnline">{{ data.isOnline ? $t('general.active') : $t('general.inactive') }}</span>
                        </template>
                    </Column>
                </DataTable>
                <br />
                <p>Never = since {{ this.formatCSDateAndTime(this.initData) }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { TerminalApi } from '../../service/TerminalApi';

import { uiSettings } from '../../settings/uiSettings';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            TerminalApi: new TerminalApi(),
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'ssbtStatus',
            activeFilter: { id: 'all', option: 'All' },
            shops: [],
            shopsModel: { _id: 'all', name: 'All' },
            defaultShopId: 'all',
            initData: '',
        };
    },
    computed: {
        activeOptions() {
            return [
                {
                    id: 'all',
                    option: this.$t('general.all'),
                },
                {
                    id: 'active',
                    option: this.$t('general.active'),
                    boolValue: true,
                },
                {
                    id: 'inactive',
                    option: this.$t('general.inactive'),
                    boolValue: false,
                },
            ];
        },
        filtredItems() {
            if (this.shopsModel._id !== 'all') {
                let filtredByShop = this.items.filter((t) => {
                    return t.shopId === this.shopsModel._id;
                });
                if (this.activeFilter.id !== 'all') {
                    return filtredByShop.filter((t) => {
                        return t.isOnline === this.activeFilter.boolValue;
                    });
                }
                return filtredByShop;
            } else {
                if (this.activeFilter.id !== 'all') {
                    return this.items.filter((t) => {
                        return t.isOnline === this.activeFilter.boolValue;
                    });
                }
            }
            return this.items;
        },
    },
    watch: {
        shopsModel() {
            if (!this.notNullOrEmptyObject(this.shopsModel)) {
                this.shopsModel = { _id: 'all', option: 'All' };
            }
        },
        activeFilter() {
            if (!this.notNullOrEmptyObject(this.activeFilter)) {
                this.activeFilter = { id: 'all', option: 'All' };
            }
        },
    },
    mounted() {
        this.BoApi.getShops('')
            .then((response) => {
                this.shops = response.data;
                this.shops.unshift(this.shopsModel);
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
        this.readItems();
    },
    methods: {
        readItems() {
            this.loading = true;
            this.TerminalApi.getSsbtData()
                .then((response) => {
                    this.items = response.data.terminals;
                    this.loading = false;
                    this.initData = response.data.initTime;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
    },
};
</script>
